import React, { memo } from 'react'
import PropTypes from 'prop-types'

import Button from 'components/atoms/buttons/Button'
import Flex from 'components/atoms/Flex'

const SubmitButton = ({ buttonProps }) => {
  return (
    <Flex fullWidth justifyContent={{ xs: 'center', lg: 'flex-start' }}>
      <Button
        type="submit"
        mx={{ xs: 'auto', lg: 0 }}
        className="double-border sharp dark-bg xl"
        {...buttonProps}
      >
        Submit
      </Button>
    </Flex>
  )
}

SubmitButton.propTypes = {
  containerProps: PropTypes.object.isRequired,
  buttonProps: PropTypes.object.isRequired,
}

export default memo(SubmitButton)
