import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Grid from '@material-ui/core/Grid'
import { Formik, Form as FormikForm, Field } from 'formik'
import { string, object } from 'yup'
import { Base64 } from 'js-base64'

import { useGlobalActionsContext } from 'context/GlobalContext'
import encodeForm from 'helpers/encodeForm'

import SectionHeading from 'components/atoms/headings/SectionHeading'
import Box from 'components/atoms/Box'
import TextField from 'components/atoms/inputs/TextField'
import SubmitButton from 'components/atoms/forms/SubmitButton'
import FormGridItem from 'components/atoms/forms/FormGridItem'

const CollectionForm = ({
  heading,
  collectionItems,
  collection,
  visible,
  siteUrl,
  formName,
}) => {
  const { setIsLoading, setStatusMessage } = useGlobalActionsContext()

  let lineItems = ''
  collectionItems.map((item) => {
    lineItems +=
      item.product.collection.collectionName +
      ' - ' +
      item.lineItem.productName +
      ' (quantity: ' +
      item.collectionItem.quantity +
      ')\r\n\r\n'
  })

  const handleSubmit = (values, actions) => {
    const linkObj = {
      collection: [...collection],
      customer: {
        firstName: values.firstName,
        surname: values.surname,
        email: values.email,
        phone: values.phone,
        message: values.message,
      },
    }

    values.collection = lineItems
    values.collectionLink = `beachwoodinteriors.com/viewCollection?collection=${Base64.encode(
      JSON.stringify(linkObj)
    )}`

    setIsLoading(true)
    fetch('/?no-cache=1', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: encodeForm({ 'form-name': formName, ...values }),
    })
      .then(() => {
        setIsLoading(false)
        setStatusMessage({
          type: 'success',
          message:
            'Your collection was successfully sent. We will get back to you soon.',
        })
        actions.resetForm()
      })
      .catch(() => {
        setIsLoading(false)
        setStatusMessage({
          type: 'error',
          message:
            'Your collection submission was unsuccessful. Please try again.',
        })
      })
      .finally(() => actions.setSubmitting(false))
  }

  return (
    <Box
      mt={10}
      bg="greyLight"
      px={11}
      py={8}
      display={visible ? 'block' : 'none'}
    >
      <SectionHeading>{heading}</SectionHeading>

      <Formik
        initialValues={{
          firstName: '',
          surname: '',
          email: '',
          phone: '',
          message: '',
          collection: lineItems,
          collectionLink: siteUrl,
        }}
        validationSchema={object().shape({
          firstName: string().required('Please enter your first name'),
          surname: string().required('Please enter your last name'),
          email: string()
            .email('Please supply a valid email address')
            .required('Email address is Required'),
          phone: string(),
          message: string().required('A message is required'),
        })}
        onSubmit={(values, actions) => {
          handleSubmit(values, actions)
        }}
      >
        <FormikForm name={formName} data-netlify={true}>
          <textarea
            value={siteUrl}
            name="collectionLink"
            style={{ display: 'none' }}
          />
          <input type="hidden" value={lineItems} name="collection" />
          <Grid container spacing={6}>
            <FormGridItem xs={12} md={6}>
              <TextField
                name="firstName"
                label="First Name"
                fullWidth
                required
              />
            </FormGridItem>
            <FormGridItem xs={12} md={6}>
              <TextField name="surname" label="Surname" fullWidth required />
            </FormGridItem>
            <FormGridItem xs={12} md={6}>
              <TextField
                name="email"
                label="Email"
                fullWidth
                required
                type="email"
              />
            </FormGridItem>
            <FormGridItem xs={12} md={6}>
              <TextField name="phone" label="Phone" fullWidth />
            </FormGridItem>
            <FormGridItem xs={12}>
              <TextField
                name="message"
                label="Your Message"
                multiline
                rowsMax={5}
                fullWidth
                required
              />
            </FormGridItem>
          </Grid>
          <SubmitButton buttonProps={{ mt: 6 }} />
        </FormikForm>
      </Formik>
    </Box>
  )
}

CollectionForm.propTypes = {
  heading: PropTypes.string.isRequired,
  siteUrl: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  collectionItems: PropTypes.array.isRequired,
  collection: PropTypes.array.isRequired,
  visible: PropTypes.bool,
}

const CollectionFormWithQuery = (props) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `}
    render={(data) => <CollectionForm {...data.site.siteMetadata} {...props} />}
  />
)

export default memo(CollectionFormWithQuery)
