import React, { memo } from 'react'
import PropTypes from 'prop-types'
import TextFieldBase from '@material-ui/core/TextField'
import { Field } from 'formik'

const TextField = ({ name, label, ...props }) => {
  return (
    <Field name={name}>
      {({ field, meta }) => {
        const hasError = meta.touched && meta.error
        const valid = meta.touched && !meta.error

        return (
          <TextFieldBase
            inputProps={{ ...field }}
            className={valid ? 'valid' : ''}
            label={label}
            error={hasError}
            {...props}
          />
        )
      }}
    </Field>
  )
}

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

export default memo(TextField)
