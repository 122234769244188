import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'

import { useCollectionActionsContext } from 'context/CollectionContext'
import Flex from 'components/atoms/Flex'

const LeftIconButton = styled(IconButton)`
  border: 1px solid ${(props) => props.theme.colors.outlineDark};
  border-right: none;
  border-radius: 50% 0 0 50%;
`
const RightIconButton = styled(IconButton)`
  border: 1px solid ${(props) => props.theme.colors.outlineDark};
  border-left: none;
  border-radius: 0 50% 50% 0;
`

const Quantity = styled(Flex)`
  border-top: 1px solid ${(props) => props.theme.colors.outlineDark};
  border-bottom: 1px solid ${(props) => props.theme.colors.outlineDark};
  align-self: stretch;
  min-width: 2rem;
  padding: 0 1rem;
`

const QuantityInput = ({ name, collectionItem, handleSubmit }) => {
  const { updateQuantity } = useCollectionActionsContext()

  return (
    <Flex center>
      <LeftIconButton
        aria-label="delete"
        onClick={() =>
          updateQuantity(collectionItem, collectionItem.quantity - 1, false)
        }
      >
        <RemoveIcon />
      </LeftIconButton>
      <Quantity center>{collectionItem.quantity}</Quantity>
      <RightIconButton
        aria-label="delete"
        onClick={() =>
          updateQuantity(collectionItem, collectionItem.quantity + 1, false)
        }
      >
        <AddIcon />
      </RightIconButton>
    </Flex>
  )
}

QuantityInput.propTypes = {
  name: PropTypes.string.isRequired,
  initialValue: PropTypes.number.isRequired,
}

export default memo(QuantityInput)
